import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import TextArticle from '@src/components/TextArticle/TextArticle';

const KlagomalPage = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const headerText =
    data.contentfulPage.pageHeaderDescription.pageHeaderDescription;

  return (
    <Layout theme="white">
      <SEO title={headLine} noIndex />
      <TextArticle style={{ marginTop: 64, marginBottom: 32 }}>
        {headerText}
      </TextArticle>
    </Layout>
  );
};

export default KlagomalPage;

KlagomalPage.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query Klagomal {
    contentfulPage(pageUrl: { eq: "/klagomal" }) {
      pageHeader
      pageHeaderShort
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      headLine
    }
  }
`;
